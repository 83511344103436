import axios from 'axios'

import securityUtils from '@/utils/security'

const API_URL = process.env.VUE_APP_API_URL

const ERROR_MAP = {
    400: 'Please enter all required fields',
    401: 'Login session expired',
    403: 'Access denied',
    404: 'Resource not found',
    409: 'An account with this email address already exists',
    500: 'System error, please try again or contact an official for assistance',
    503: 'Could not connect to API server'
}


/**
 * Inject authorization header if we ave an aut token
 */
axios.interceptors.request.use(function(config) {
    let authToken = securityUtils.getAuthToken()
    if (authToken !== null) {
        config.headers.common['Authorization'] = `Bearer ${authToken}`
    }
    return config
})

/**
 * Handle authorization response errors
 */
axios.interceptors.response.use(function (response) {
    return response
}, function (error) {

    let statusCode = error.response.status
    let pathName = window.location.pathname

    switch (statusCode) {
        case 401:
            if (pathName === '/login') {
                return Promise.reject(error)
            }
            window.location = `/login?errorCode=${statusCode}`
            break

        case 403:
            if (securityUtils.isLoggedIn()) {
                return Promise.reject(error)
            }
            window.location = `/login?errorCode=${statusCode}`
            break

        case 400:
        case 404:
        case 409:
            return Promise.reject(error)

        default:
            window.location = `/error?errorCode=${statusCode}`
            break
    }
})


export default {
    getErrorMap() {
        return ERROR_MAP
    },


    /**
     * Parse API response error
     * @param error
     * @param getRawMessage
     * @return {*}
     */
    parseResponseError(error, getRawMessage=false) {
        if (error.response == undefined) {
            return ERROR_MAP[500]
        }

        if (getRawMessage && error.response.data.message !== undefined) {
            return error.response.data.message
        }

        let statusCode = error.response.status.toString()
        return ERROR_MAP[statusCode] || error.response.data.message
    },

    /**
     * Authenticate API
     * @param username
     * @param password
     * @return {Promise<AxiosResponse<any>>}
     */
    authenticate(username, password) {
        let payload = {
            email: username,
            password: password
        }
        return axios.post(`${API_URL}/auth/token`, payload)
    },

    /**
     * Make crate user POST call
     * @param payload
     */
    createUser(payload) {
        return axios.post(`${API_URL}/users`, payload)
    },

    /**
     * Make update user PUT call
     * @param id
     * @param payload
     */
    updateUser(id, payload) {
        return axios.put(`${API_URL}/users/${id}`, payload)
    },

    /**
     * Make update password PUT call
     * @param id
     * @param payload
     */
    updatePassword(id, payload) {
        return axios.put(`${API_URL}/users/${id}/password`, payload)
    },

    /**
     * Make password reset POST call
     * @param payload
     */
    generateResetPassword(payload) {
        return axios.post(`${API_URL}/passwordreset/generate`, payload)
    },


    /**
     * Make generate password reset POST call
     * @param token
     * @param payload
     */
    resetPassword(payload) {
        return axios.post(`${API_URL}/passwordreset/reset`, payload)
    },



    /**
     * Fetch a user by id
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchUser(id) {
        return axios.get(`${API_URL}/users/${id}`)
    },

    /**
     * Fetch relation types
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchRelationTypes() {
        return axios.get(`${API_URL}/relationtypes`)
    },

    /**
     * Fetch a user dependents
     * @param userId
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchDependents(userId) {
        return axios.get(`${API_URL}/users/${userId}/dependents`)
    },

    /**
     * Add dependent to user
     * @param userId
     * @param payload
     */
    addDependent(userId, payload) {
        return axios.post(`${API_URL}/users/${userId}/dependents`, payload)
    },

    /**
     * Fetch a user dependent
     * @param userId
     * @param dependentId
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchDependent(userId, dependentId) {
        return axios.get(`${API_URL}/users/${userId}/dependents/${dependentId}`)
    },

    /**
     * Update dependent PUT action
     * @param userId
     * @param dependentId
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateDependent(userId, dependentId, payload) {
        return axios.put(`${API_URL}/users/${userId}/dependents/${dependentId}`, payload)
    },

    /**
     * Fetch IdTypes
     * @return {Promise<AxiosResponse<any>>}
     */
    fetchIdTypes() {
        return axios.get(`${API_URL}/idtypes`)
    },

    /**
     * Fetch qualifications
     * @return {Promise<AxiosResponse<any>>}
     */
    fetchQualifications() {
        return axios.get(`${API_URL}/users/qualifications`)
    },

    /**
     * Add claim to user
     * @param userId
     * @param payload
     */
    addClaim(userId, payload) {
        return axios.post(`${API_URL}/users/${userId}/claims`, payload)
    },

    /**
     * Fetch a user claims
     * @param userId
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchClaims(userId) {
        return axios.get(`${API_URL}/users/${userId}/claims`)
    },

    /**
     * Fetch a user claim
     * @param userId
     * @param claimId
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchClaim(userId, claimId) {
        return axios.get(`${API_URL}/users/${userId}/claims/${claimId}`)
    },

    /**
     * Update claim PUT action
     * @param userId
     * @param claimId
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateClaim(userId, claimId, payload) {
        return axios.put(`${API_URL}/users/${userId}/claims/${claimId}`, payload)
    },

    /**
     * Fetch a user invoices
     * @param userId
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchInvoices(userId) {
        return axios.get(`${API_URL}/users/${userId}/invoices`)
    },

    /**
     * Fetch a invoice
     * @param userId
     * @param invoiceId
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchInvoice(userId, invoiceId) {
        return axios.get(`${API_URL}/users/${userId}/invoices/${invoiceId}`)
    },

    /**
     * Create payment for invoice
     *
     * @param userId
     * @param invoiceId
     * @param payload
     */
    createPayment(userId, invoiceId, payload) {
        return axios.post(`${API_URL}/users/${userId}/invoices/${invoiceId}/payments`, payload)
    },

    /**
     * Fetch user recommenders
     * @param userId
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    createRecommender(userId, payload) {
        return axios.post(`${API_URL}/users/${userId}/recommenders`, payload)
    },

    /**
     * Fetch user recommenders
     * @param userId
     * @param recId
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateRecommender(userId, recId, payload) {
        return axios.put(`${API_URL}/users/${userId}/recommenders/${recId}`, payload)
    },

    /**
     * Fetch user recommenders
     * @param userId
     * @returns {Promise<AxiosResponse<any>>}
     */
    fetchRecommenders(userId) {
        return axios.get(`${API_URL}/users/${userId}/recommenders`)
    },
}


